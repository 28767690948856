import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from "./pages/Home/Home";
import Shop from "./pages/Shop/Shop";
import Seasonal from "./pages/Seasonal/Seasonal";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import FAQ from "./pages/FAQ/FAQ";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import Navbar from "./components/Navbar/Navbar";

function App() {
  return (
      <Router>
          <Navbar />
          <Routes>
              <Route path="/" element={<Home/>}/>
              {/*<Route path="/shop" element={<Shop/>}/>*/}
              {/*<Route path="/seasonal" element={<Seasonal/>}/>*/}
              {/*<Route path="/about" element={<About/>}/>*/}
              {/*<Route path="/contact" element={<Contact/>}/>*/}
              {/*<Route path="/faq" element={<FAQ/>}/>*/}
              <Route path="/shop" element={<Home/>}/>
              <Route path="/seasonal" element={<Home/>}/>
              <Route path="/about" element={<Home/>}/>
              <Route path="/contact" element={<Home/>}/>
              <Route path="/faq" element={<Home/>}/>
              <Route path="*" element={<ErrorPage/>}/>
          </Routes>
      </Router>
  );
}

export default App;
