import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import tiktok from "../../assets/logo/tiktok.svg"
import fb from "../../assets/logo/fb.svg"
import insta from "../../assets/logo/insta.svg"
import logo from "../../assets/logo/loko.svg"
import { useTheme } from '@mui/material/styles';

import { useLocation } from "react-router-dom"

const THEME_COLORS = {
    foregroundColor: '#06063E',
    backgroundColor: '#fff'
};

const pagesLeftMap = {
    HOME: {
        path: '/',
        papelWidth: '149px',
        selected: false
    },
    SHOP: {
        path: '/shop',
        papelWidth: '149px',
        selected: false
    },
    SEASONAL: {
        path: '/seasonal',
        papelWidth: '249px',
        selected: false
    }
}

const pagesRightMap = {
    ABOUT: {
        path: '/about',
        papelWidth: '149px',
        selected: false
    },
    CONTACT: {
        path: '/contact',
        papelWidth: '199px',
        selected: false
    },
    FAQ: {
        path: '/faq',
        papelWidth: '99px',
        selected: false
    }
}

const allPages = {
    ...pagesLeftMap,
    ...pagesRightMap
};

const NavBar = () => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [pagesSelectedLeft, setPagesSelectedLeft] = React.useState(pagesLeftMap);
    const [pagesSelectedRight, setPagesSelectedRight] = React.useState(pagesRightMap);
    const [homeButton, setHomeButton] = React.useState(false);
    const location = useLocation();
    const theme = useTheme();


    React.useEffect(() => {
        // Runs after EVERY rendering
        const selectedPathLeft = Object.keys(pagesSelectedLeft).filter(each => pagesSelectedLeft[each].path === location.pathname)
        if (selectedPathLeft.length !== 0) {
            pagesSelectedLeft[selectedPathLeft[0]].selected = true
        }
        const selectedPathRight = Object.keys(pagesSelectedRight).filter(each => pagesSelectedRight[each].path === location.pathname)
        if (selectedPathRight.length !== 0) {
            pagesSelectedRight[selectedPathRight[0]].selected = true
        }
    });

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const papelHandler = (name, pages) => event => {
        if (!pages[name].selected) {
            event.target.nextSibling.style.backgroundImage= "url(/papel.png)";
        }

    };
    const papelHandlerOut = (name, pages) => event => {
        if (!pages[name].selected) {
            event.target.nextSibling.style.backgroundImage = "none";
        }
    };

    const container = {
        [theme.breakpoints.up('md')]: {
            // backgroundColor: 'red',
            px: 2,
        }
    }

    return (
        <React.Fragment>
            <AppBar sx={{px: 2, boxShadow: 'none', backgroundColor: THEME_COLORS.backgroundColor}} position="static">
                <Container maxWidth="xl">
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        <a href='https://www.facebook.com/lokosoaps' target="_blank"  rel="noreferrer"><svg width="40" height="40">
                            <image href={fb} width="40" height="40"/>
                        </svg></a>
                        <a href='https://www.instagram.com/lokosoaps/' target="_blank"  rel="noreferrer"><svg width="40" height="40">
                            <image href={tiktok} width="40" height="40"/>
                        </svg></a>
                        <a href='https://www.tiktok.com/@lokosoaps' target="_blank"  rel="noreferrer"><svg width="40" height="40">
                            <image href={insta} width="40" height="40"/>
                        </svg></a>
                    </Box>
                </Container>
            </AppBar>
            <AppBar sx={{py:1, boxShadow: 'none', backgroundColor: THEME_COLORS.backgroundColor}} position="static">
                <Container maxWidth="none" sx={container}>
                    <Toolbar disableGutters >
                        <Box sx={{ flexGrow: 1, justifyContent: 'right', display: { xs: 'none', md: 'flex' } }}>
                            {Object.keys(pagesSelectedLeft).map((page) => (
                                <div key={page.toLowerCase()}

                                >
                                    <Button
                                        onMouseEnter={papelHandler(page, pagesSelectedLeft)}
                                        onMouseLeave={papelHandlerOut(page, pagesSelectedLeft)}
                                        href={pagesSelectedLeft[page].path}
                                        sx={{ textAlign: 'center', borderRadius: '3px 3px 0px 0px', zIndex: 1000, backgroundColor: THEME_COLORS.backgroundColor, fontFamily: 'Playfair Display', fontSize: '30px', mx: 1, color: THEME_COLORS.foregroundColor, display: 'block',
                                            '&:hover': {
                                                backgroundColor: THEME_COLORS.foregroundColor,
                                                color: THEME_COLORS.backgroundColor,
                                                transition: 'all .8s ease',
                                                // '-webkit-transition': 'all .8s ease'
                                            }
                                        }}
                                    >
                                        {page}
                                    </Button>
                                    {
                                        pagesSelectedLeft[page].path === location.pathname || pagesSelectedLeft[page].selected ? <div style={{ backgroundImage: "url(/papel.png)", width: `${pagesSelectedLeft[page].papelWidth}`, backgroundSize: '50px', marginLeft: '8px', marginRight: '8px', zIndex: 1, position: 'relative', top: '-1px', height: '26px'}}></div> :
                                         <div style={{ backgroundImage: "none",width: `${pagesSelectedLeft[page].papelWidth}`, backgroundSize: '50px', marginLeft: '8px', marginRight: '8px', zIndex: 1, position: 'relative', top: '-1px', height: '26px'}}></div>
                                    }

                                </div>
                            ))}
                        </Box>
                        <Box sx={{ ml: 2, mr: 2, display: { xs: 'none', md: 'flex' } }}>
                            <a href="/">
                                <svg width="90" height="90">
                                    <image href={logo} width="90" height="90"/>
                                </svg>
                            </a>
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {Object.keys(pagesSelectedRight).map((page) => (
                                <div key={page.toLowerCase()}

                                >
                                    <Button
                                        onMouseEnter={papelHandler(page, pagesSelectedRight)}
                                        onMouseLeave={papelHandlerOut(page, pagesSelectedRight)}
                                        href={pagesSelectedRight[page].path}
                                        sx={{ textAlign: 'center', borderRadius: '3px 3px 0px 0px', zIndex: 1000, backgroundColor: THEME_COLORS.backgroundColor, fontFamily: 'Playfair Display', fontSize: '30px', mx: 1, color: THEME_COLORS.foregroundColor, display: 'block',
                                            '&:hover': {
                                                backgroundColor: THEME_COLORS.foregroundColor,
                                                color: THEME_COLORS.backgroundColor,
                                                transition: 'all .8s ease',
                                                // '-webkit-transition': 'all .8s ease'
                                            }
                                        }}
                                    >
                                        {page}
                                    </Button>
                                    {
                                        pagesSelectedRight[page].path === location.pathname || pagesSelectedRight[page].selected ? <div style={{ backgroundImage: "url(/papel.png)", width: `${pagesSelectedRight[page].papelWidth}`, backgroundSize: '50px', marginLeft: '8px', marginRight: '8px', zIndex: 1, position: 'relative', top: '-1px', height: '26px'}}></div> :
                                            <div style={{ backgroundImage: "none", width: `${pagesSelectedRight[page].papelWidth}`, backgroundSize: '50px', marginLeft: '8px', marginRight: '8px', zIndex: 1, position: 'relative', top: '-1px', height: '26px'}}></div>
                                    }

                                </div>
                            ))}
                        </Box>

                        <Box sx={{ position: { xs: 'absolute', md: 'none' }, display: { xs: 'block', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                sx={{
                                    color: THEME_COLORS.foregroundColor
                                }}
                            >
                                <MenuIcon fontSize='large' />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    color: THEME_COLORS.foregroundColor, display: { xs: 'block', md: 'none' }
                                }}
                            >
                                {Object.keys(allPages).map((page) => (
                                    <a key={page.toLowerCase()} href={allPages[page].path} style={{textDecoration: 'none', color: THEME_COLORS.foregroundColor}}>
                                        <MenuItem key={page.toLowerCase()} onClick={handleCloseNavMenu} sx={{
                                            '&:hover': {
                                                backgroundColor: THEME_COLORS.foregroundColor,
                                                color: THEME_COLORS.backgroundColor
                                            }}}>
                                            <Typography sx={
                                                {
                                                    fontFamily: 'Playfair Display',
                                                    fontSize: '16px',
                                                    fontWeight: 'bold'
                                                }
                                            } textAlign="center">
                                                {page}
                                            </Typography>
                                        </MenuItem>
                                    </a>

                                ))}
                            </Menu>
                        </Box>
                        <Box
                            sx={{ py: 2, flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'center' }}
                        >
                            <a href="/">
                                <svg width="60" height="60">
                                    <image href={logo} width="60" height="60"/>
                                </svg>
                            </a>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </React.Fragment>
    );
};
export default NavBar;
