import React from "react";
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const THEME_COLORS = {
    foregroundColor: '#06063E',
    backgroundColor: '#fff'
};

function Home() {
    const theme = useTheme();
    const container = {
        [theme.breakpoints.up('xl')]: {
            // backgroundColor: 'red',
            pl: 13,
            pr: 0,
            mx: 0,
            maxWidth: 'none',
            width: '100%'
        },
        [theme.breakpoints.up('lg')]: {
            // backgroundColor: 'blue',
            pl: 12,
            pr: 0,
            mx: 0,
            maxWidth: 'none',
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            // backgroundColor: 'yellow',
            pl: 22,
            pr: 0,
            mx: 0,
            maxWidth: 'none',
            width: '100%'
        },
        [theme.breakpoints.up('sm')]: {
            // backgroundColor: 'green',
            pl: 3,
            pr: 0,
            mx: 0,
            maxWidth: 'none',
            width: '100%'
        },

    }
    return (
        <Container sx={{width: '100%', maxWidth: 'none', px: 2, ...container}}>
            <Box sx={{ maxWidth: 'none', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h4" gutterBottom component="div" sx={{fontFamily: 'Playfair Display', color: THEME_COLORS.foregroundColor}}>
                    Website Under Construction.
                </Typography>
                <Typography variant="h4" gutterBottom component="div" sx={{fontFamily: 'Playfair Display', color: THEME_COLORS.foregroundColor}}>
                    Inventory Coming Soon!
                </Typography>
            </Box>
        </Container>)
}

export default Home;