import React from "react";
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';

function About() {
    const theme = useTheme();
    const container = {
        [theme.breakpoints.up('xl')]: {
            // backgroundColor: 'red',
            px: 21,
        }
    }
    return (<Container maxWidth="xl" sx={{px: 2, ...container}}><div>This is the about page</div></Container>)
}

export default About;